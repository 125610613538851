import { Component, EventEmitter, Input, OnInit, Output, ChangeDetectorRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ProductVersionImageModel, ProductVersionModel } from '../../../models/product/product-version.model';
import { TokenOtkViewModel } from '../../../models/tokens/token.model';
import { CommonService, ProductService } from '../../../services';

@Component({
  selector: 'app-token-table',
  templateUrl: './token-table.component.html',
  styleUrls: ['./token-table.component.scss']
})
export class TokenTableComponent implements OnInit {
  
  tokenLoading: boolean;
  @Input() model: ProductVersionModel;
  tokens: Array<TokenOtkViewModel> = [];
  tokensOriginal: Array<TokenOtkViewModel> = [];

  @Input() selectedImage: ProductVersionImageModel;
  @Output() countChanged: EventEmitter<number> =   new EventEmitter();
   

  constructor(
    private readonly prodSrv: ProductService,
     private readonly toastr: ToastrService,
     private readonly cdref: ChangeDetectorRef,
     public common: CommonService
     ) { }

  ngOnInit(): void {
    this.getTokens(false);
  }

  ngDoCheck() {
    this.reloadLbl();
}
ngAfterViewInit(){
    this.reloadLbl();
}

reloadLbl(){
       
    this.cdref.detectChanges();
}

refresh() {
  this.getTokens(true);
}

  getTokens(force?: boolean) {
    this.tokenLoading = true;
    this.prodSrv.getTokensByVersion(this.model.id, force).subscribe(
      data => this.onGetTokens(data),
      err => this.onGetTokensError(err));
  }

  onGetTokens(data) {
    this.tokenLoading = false;
    if (data['result']) {
      this.tokens = [];
      this.tokensOriginal = [];
      data['result'].forEach(x => {
        this.tokens.push(TokenOtkViewModel.parse(x));
        this.tokensOriginal.push(TokenOtkViewModel.parse(x));
      });
    }
  }
  
  onGetTokensError(err) {
    this.tokenLoading = false;
    this.onError(err);
  }

  onError(_) {
    this.toastr.error('Something went wrong!');
  }

  openInvoice(id){
    this.countChanged.emit(id);
  }

}
