import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CoreService } from './core.service';
import { RegistrationModel } from '../models/user/registration.model';
import { ResponseModel } from '../models/core/ResponseModel.model';
import { UserDetailModel } from '../models/user/user-detail.model';

@Injectable()
export class UserService {
    constructor(private srv: CoreService) {

    }

    public registerUser(model: RegistrationModel): Observable<ResponseModel<any>> {
        return this.srv.post(this.srv.baseUrl + '/user/register/', model);
    }
    public resetPassword(code: string, email: string, password: string): any {
        return this.srv.post(`${this.srv.baseUrl}/user/resetPassword`,
            { code: code, email: email, password: password });
    }
    public recoverPassword(x: string) {
        return this.srv.get(`${this.srv.baseUrl}/user/recoverPassword?email=${encodeURIComponent(x)}`);
    }
    public confirmRegistrationEmail(email: string, code: string) {
        return this.srv.post(`${this.srv.baseUrl}/user/confirmRegistrationEmail`,
            { code: code, email: email });
    }
    public getRegCodes(): Observable<any> {
        return this.srv.get(`${this.srv.baseUrl}/user/getRegCodes`);
    }

    public getUserById(id: string): Observable<any> {
        return this.srv.get(`${this.srv.baseUrl}/user/getUserById/${id}`);
    }

    public getUserByToken(): Observable<any> {
        return this.srv.get(`${this.srv.baseUrl}/user/getCurrentUser`);
    }


    public getRoleGroups(): Observable<any> {
        return this.srv.get(`${this.srv.identityUrl}/role/getRoleGroups/`);
    }

    public getMyGroupRoles(id): Observable<any> {
        return this.srv.get(`${this.srv.identityUrl}/role/getGroupRolesByUserId?userId=${id}`);
    }

    public updateUserRolesGroup(id: string, roles: Array<string>): Observable<any> {
        let query = `${this.srv.identityUrl}/role/updateUserRolesGroup?user=${id}&groups=Internal-Manager`;
        let group = []
        roles.forEach(x => group.push(x))
        return this.srv.post(query, group);
    }

    public getRoles(): Observable<any> {
        return this.srv.get(`${this.srv.baseUrl}/user/getRoles/`);
    }

    public createRegCodeModel(model): Observable<any> {
        return this.srv.post(`${this.srv.baseUrl}/user/createRegCode`, model);
    }

    public getUsers(): Observable<any> {
        return this.srv.get(`${this.srv.baseUrl}/user/getUsers`);
    }

    public getUsersTab(tier: number, force: boolean): Observable<any> {
        return this.srv.get(`${this.srv.baseUrl}/user/getUsers/${tier}?force=${force}`);
    }
    public getResellerUsers(id: number): Observable<any> {
        return this.srv.get(`${this.srv.baseUrl}/User/getResellerUsers/${id}`);
    }

    public getTier0Users(force: boolean): Observable<any> {
        return this.srv.get(`${this.srv.baseUrl}/User/getUsersTier0?force=${force}`);
    }

    public createUser(model: UserDetailModel): Observable<any> {
        return this.srv.post(`${this.srv.baseUrl}/User/createUser`, model);
    }

    public updateUser(model: UserDetailModel): Observable<any> {
        return this.srv.post(`${this.srv.baseUrl}/User/updateUser`, model);
    }

    public isEmailExist(email: string): Observable<ResponseModel<boolean>> {
        return this.srv.get(`${this.srv.baseUrl}/User/EmailExist?email=${email}`);
    }
    public disableUser(userid: string, disable: boolean): Observable<ResponseModel<boolean>> {
        return this.srv.post(`${this.srv.baseUrl}/user/disableUser?userid=${userid}&disable=${disable}`, {});
    }

    public getImportUsers(force: boolean): Observable<any> {
        return this.srv.get(`${this.srv.baseUrl}/User/getImportUsers?force=${force}`);
    }

    public postImportUser(model: UserDetailModel): Observable<any> {
        return this.srv.post(`${this.srv.baseUrl}/User/postImportUser`, model);
    }

    public changeLanguage(lang: string) {
        return this.srv.post(`${this.srv.storeUrl}/user/changeLanguage?lang=${lang}`, {});
    }

}
