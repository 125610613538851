import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AccountContactModel, AccountModel } from '../../../models/account.model';
import { AccountService } from '../../../services/account.service';
import { ModalTransferModel } from '../../../models/modal-transfer.model';
import { ConfigurationService } from '../../../services/configuration.service';
import { ListItemModel } from '../../../models/list-item.model';
import { InternationalizationService, AuthService } from '../../../services';
import { ToastrService } from 'ngx-toastr';
import { RegionListItemModel } from '../../../models/region-list.model';
import { NgForm } from '@angular/forms';

@Component({
    selector: 'app-client-details-modal',
    templateUrl: './client-details-modal.component.html',
    styleUrls: ['./client-details-modal.component.scss'],
    encapsulation: ViewEncapsulation.None
})
/** client-details-modal component*/
export class ClientDetailsModalComponent implements OnInit {
    @Input() name: string;
    @Input() id: number;
    @Input() reference: ModalTransferModel;
    currentRegion:any[];
    title: string;
    account: AccountModel = <any>{};
    loading = true;
    isEditMode = false;
    isNew = false;
    billingDateOptions = ['1', '7', '15', '24', 'Last Day of Mo'];
    languages: Array<ListItemModel> = [];
    currencies: Array<ListItemModel> = [];
    taxgroups: Array<ListItemModel> = [];

    priceSheets: Array<ListItemModel> = [];
    regions: Array<RegionListItemModel> = [];
    contacts: AccountContactModel;
    isValidEmail: boolean;

    @Input() set open(open: boolean) {
        this._open = open;
      }
      get open(): boolean {
        return this._open;
      }
      private _open: boolean;

      @Output() modalClose = new EventEmitter(); 

      openClientContactDetails: boolean;
      contact: AccountContactModel | any = {};
      contactData: AccountContactModel | any = {};

      isCreate: boolean;
      @ViewChild('detailForm') detailForm: NgForm;

    constructor(public activeModal: NgbActiveModal, private srv: AccountService, private confSrv: ConfigurationService,
        private i18Svc: InternationalizationService, private auth: AuthService, private toastr: ToastrService) {
        this.i18Svc.initialize();
    }
    canView = this.auth.canViewAccounts;
    canEdit = this.auth.canEditAccounts;
    canViewCredit = this.auth.canViewCredit;

    ngOnInit(): void {
        this.isCreate = this.reference?.action === 'new';
        this.confSrv.getCurrencyOptions()
            .subscribe(data => this.onGetCurrency(data), err => this.handleError(err));

        this.confSrv.getLanguageOptions()
            .subscribe(data => this.onGetLanguage(data), err => this.handleError(err));

        this.confSrv.getTaxGroups()
            .subscribe(data => this.onGetTaxGroup(data), err => this.handleError(err));

        this.confSrv.getPriceSheetOptions()
            .subscribe(data => this.onGetPriceSheetOptions(data), err => this.handleError(err));
        this.getRegions();
        if (this.reference && this.reference.action === 'new') {
            this.edit();
            this.create(this.reference.object);
            this.onEmailValueChange();
        } else {
            this.loading = true;
            this.srv.get(this.id)
                .subscribe(data => this.onGet(data), err => this.handleError(err));
            this.getContacts();
        }

    }

    openContactModal() {
        this.openClientContactDetails = true;
    }

    closeContactModal(refresh?: boolean) {
        this.openClientContactDetails = false;
        if(refresh) {
            this.getContacts();
        }
    }

    get contactBtnText(): string {
        return this.contact?.email ? 'account-contact-modal.edit_text' : 'account-contact-modal.add_text';
    }

    getContacts() {
        this.srv.getContactsByAccountId(this.id).subscribe(data => this.onGetContacts(data), err => this.handleError(err))
    }

    onGetContacts(data) {
        this.contact = data?.result || {};
        this.contactData = JSON.parse(JSON.stringify(this.contact));
    }

    onGetPriceSheetOptions(data) {
        this.priceSheets = data['result'];
    }
    onGetLanguage(data): void {
        this.languages = data['result'];
    }

    onGetCurrency(data): void {
        this.currencies = data['result'];
    }

    onGetTaxGroup(data): void {
        this.taxgroups = data['result'].map(x => {
            x['value'] = x['name'];
            return x;
        });
    }

    onGet(data, close: boolean = false, showToastr?: boolean) {
        this.loading = false;
        this.isNew = false;
        this.account = (data['result'] as AccountModel) || <any>{};
        this.changeTitle();
        this.loading = false;
        this.onEmailValueChange();
        if(showToastr) {
            this.toastr.success('Success');
        }

        if (close) {
            this.close(true, true);
        }
    }
    isValid(): boolean {
        this.account.name = this.account.name?.trim();
        this.account.number = this.account.number?.trim();
        this.account.notes = this.account.notes?.trim();
        this.account.accountingNo = this.account.accountingNo?.trim();
        this.account.referenceNo = this.account.referenceNo?.trim();
        return this.account
            && this.account.languageId > 0
            && this.account.currencyId > 0
            && this.account.name?.length > 0
            && this.isValidEmail;
    }
    handleError(_, close: boolean = false) {
        this.loading = false;
        if (close) {
            this.close(true, false);
        }
    }

    save(close: boolean = false) {
        if (!this.isValid()) {
            return;
        }
        if (this.isNew) {
            this.loading = true;
            this.account.isActive = true;
            this.srv.create(this.account)
                .subscribe(data => this.onGet(data, close, true), err => this.handleError(err, close));
        } else {
            this.loading = true;
            this.srv.update(this.account)
                .subscribe(data => this.onGet(data, close, true), err => this.handleError(err, close));
        }
    }

    cancel() {
        this.isEditMode = false;
        this.isNew = false;
        if (this.reference && this.reference.action === 'new') {
            this.close();
        }
    }
    edit() {
        this.isEditMode = true;
    }
    create(regionId: number) {
        this.account = AccountModel.createNew(regionId) || <any>{};
        this.account.languageId = this.i18Svc.getLanguageID();
        this.isNew = true;
        this.loading = false;
        this.changeTitle();
    }

    changeTitle() {
        if (this.isNew) {
            this.title = `Create New Account (Region Id: ${this.reference.object || ''})`;
        } else {
            this.title = `Details: ${this.account.name} (${this.account.id})`;
        }
    }

    close(swal: boolean = false, success: boolean = true) {
        this.reference.close();
        const actionVerbS = this.isNew ? 'created' : 'updated';
        const actionVerbError = this.isNew ? 'create' : 'update';
        if (swal) {
            if (success) {
                this.reference.setSuccess(`Account  ${actionVerbS}`,
                    `your account have been  ${actionVerbS}: ${this.account.name}`);
            } else {
                this.reference.setError('Account error',
                    `failed to ${actionVerbError} account: ${this.account.name}`);
            }
        }
        this.open = false;
        this.modalClose.emit(false);
    }
    
    getRegions() {
        this.srv.getAllRegions()
          .subscribe(data => this.onRegionGet(data), err => this.onRegioGetError(err));
      }
    
      onRegionGet(data) {
        if (data.result) {
          this.regions = [];
          data.result.forEach(x => this.regions.push(RegionListItemModel.Parse(x)));
          this.currentRegion = data.result.filter(res => res.id === this.account?.regionId);
          this.account.currencyId = this.currentRegion[0]?.currency.id;
          this.account.taxId = this.currentRegion[0]?.taxDefaultId;
          this.account.priceSheetId = this.currentRegion[0]?.priceList.id;
        } else {
          this.onRegioGetError(data.errorMessage);
        }
      }
        
      onRegioGetError(err) {
        console.log(err);
      }

      onEmailValueChange() {
          this.isValidEmail = !this.detailForm?.form?.controls?.notificationEmail?.errors?.email;
      }
}
