import {Directive, Input, HostBinding} from '@angular/core'
@Directive({
    selector: 'img:not(.optional-image)',
    host: {
      '(error)':'updateUrl()',
      '[src]':'src'
     }
  })
  
 export class ImagePreloadDirective {
    @Input() src:string;
    @HostBinding('class') className
    default: string = 'assets/img/logo/No_Image_Available.jpg';
    updateUrl() {
      this.src = this.default;
    }
  }