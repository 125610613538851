import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ModalTransferModel } from '../../../models/modal-transfer.model';
import { ListItemModel } from '../../../models/list-item.model';
import { PriceListItemModel } from '../../../models/product/price-list/price-list.model';
import { ProductService } from '../../../services/product.service';
import { ConfigurationService } from '../../../services/configuration.service';
import { ProductVersionModel, ProductVersionImageModel, ProductVersionVerboseModel } from '../../../models/product/product-version.model';
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { ProductModel } from '../../../models/product/product.model';
import { ToastrService } from 'ngx-toastr';
import { ExcelService, InternationalizationService } from '../../../services';
import { ProductAlertModalComponent } from '../product-alert-modal/product-alert-modal.component';
import { InvoiceViewModalComponent } from '../invoice-view-modal/invoice-view-modal.component';
import * as XLSX from 'xlsx';
import { DragulaService } from 'ng2-dragula';
import { Subscription } from 'rxjs';
import { TokenOtkViewModel } from '../../../models/tokens/token.model';
import { TokenTableComponent } from '../token-table/token-table.component';

@Component({
  selector: 'app-product-version-edit-modal',
  templateUrl: './product-version-edit-modal.component.html',
  styleUrls: ['./product-version-edit-modal.component.scss']
})

export class ProductVersionEditModalComponent implements OnInit, OnDestroy {
  @Input() name: string;
  @Input() id: number;
  @Input() reference: ModalTransferModel;
  languages: Array<{ id: number, name: string }> = [{ id: 1, name: 'EN' }, { id: 2, name: 'FR' }];
  model: ProductVersionModel;
  selectedNav = 1;
  selectedDescNav = 1;
  title: string;
  currencyOptions: Array<ListItemModel> = [];
  productOptions: Array<ListItemModel> = [];
  languageOptions: Array<ListItemModel> = [];
  typeOptions: Array<ListItemModel> = [];
  channelOptions: Array<ListItemModel> = [];
  selectedVersionLang: ProductVersionVerboseModel;
  versionLang: Array<ProductVersionVerboseModel> = [];
  versionLangOriginal: Array<ProductVersionVerboseModel> = [];
  versionImages: Array<ProductVersionImageModel> = [];
  versionImagesOriginal: Array<ProductVersionImageModel> = [];
  inputVal:number = 0;
  newProduct: ProductModel;

  products: Array<PriceListItemModel> = [];

  addOns: Array<PriceListItemModel> = [];

  dropzoneConfig: DropzoneConfigInterface;

  tagsOnVersion: Array<ListItemModel> = [];
  tagsAvailable: Array<ListItemModel> = [];

  tagsAddOnOnVersion: Array<ListItemModel> = [];
  tagsAddOnAvailable: Array<ListItemModel> = [];

  bundlerDisplayedColumns: string[] = ['name', 'internalSku',
    'cost', 'items', 'sortingOrder', 'validFrom', 'validTo'];
  imageDisplayedColumns: string[] = ['url', 'alt', 'copy'];

  promoCodes: any[] = [];
  marketingCamps: any[] = [];

  isNew = true;

  descriptionModified = false;
  imagesModified = false;
  tagsModified = false;

  requestSent = 0;
  errorMsg = '';

  tableState = 0;
  imgLoading: boolean;
  selectedImage: ProductVersionImageModel;
  isImageTabActive: boolean;
  hideModal: boolean;
  healthCheckResult = [];
  imgFlag: boolean = false;
  @Input() set open(open: boolean) {
    this._open = open;
  }
  get open(): boolean {
    return this._open;
  }
  private _open: boolean;

  @Output() modalClose = new EventEmitter();

  selectedLanguage: string;

  openProductAlertModal: boolean;
  modalRef: ModalTransferModel;
  openHealthCheckModal: boolean;

  dimensionsUnitOptions: string[] = ['Inch', 'Centimeter'];
  weightUnitOptions: string[] = ['Pound', 'Ounce', 'Gram', 'Kilogram'];
  @ViewChildren('versionImagesList') versionImagesList: QueryList<ElementRef>;

  loadingProduct: boolean;
  loadingVersion: boolean;
  subs = new Subscription();

  isChanged: boolean;

  @ViewChild('tokenTable') tokenTable: TokenTableComponent;

  constructor(private readonly prodSrv: ProductService,
    private readonly confSrv: ConfigurationService, private readonly toastr: ToastrService, private readonly i18Svc: InternationalizationService, private readonly exportService: ExcelService,
    private readonly dragula: DragulaService) {
    this.i18Svc.initialize();
  }

  ngOnInit(): void {
    this.initAction();
    this.dropzoneConfig = {
      url: this.prodSrv.uploadImageUrl(this.model.id),
      maxFilesize: 10,
      acceptedFiles: 'image/*'
    };

    this.subs.add(this.dragula
      .drop("PERSON")
      .subscribe(() => {
        const arr = new Array;
        this.versionImages.forEach(res =>{
         arr.push(res.id)
         
        })
        this.prodSrv.productRearrangePriority(arr).subscribe(res => {
         console.log(res);
        })
        
      }));

      this.subs.add(this.dragula.dropModel("DRAGULA_EVENTS").subscribe(({ name, el, target, source, item, sourceModel, targetModel, sourceIndex, targetIndex }) => {
        if(source?.id !== target?.id) {
          if(target?.id === "tagsOnVersion-container") {
            const isDuplicateVersion = this.tagsOnVersion.find(x => JSON.stringify(x) === JSON.stringify(item));
            if(!isDuplicateVersion) {
              this.tagsModified = true;
            } else {
              setTimeout(() => {
                this.tagsOnVersion?.splice(targetIndex, 1);
                this.tagsAvailable?.splice(sourceIndex, 0, item);
              }, 1);
            }
          } else {
            const isDuplicateVersion = this.tagsAvailable.find(x => JSON.stringify(x) === JSON.stringify(item));
            if(!isDuplicateVersion) {
              this.tagsModified = true;
            } else {
              setTimeout(() => {
                this.tagsAvailable?.splice(targetIndex, 1);
                this.tagsOnVersion?.splice(sourceIndex, 0, item);
              }, 1);
            }
          }
        }
      }))
  }

  resetValues() {
    this.newProduct = ProductModel.create();
    this.descriptionModified = false;
    this.imagesModified = false;
    this.tagsModified = false;
  }
  initAction() {
    this.resetValues();
    if (this.reference.action === 'copy') {
      this.model = ProductVersionModel.parse(this.reference.object);
      this.model.internalSku = `${this.model.internalSku}-Copy`;
      this.title = `Cloning ${this.model.internalSku}`;
      this.model.id = 0;
      this.getImages();
      this.initTags();
      // TODO: IMPLEMENT AND TEST
    } else if (this.reference.action === 'new') {
      this.model = ProductVersionModel.create();
      this.model.channelId = this.reference.object.channelId;
      this.model.productId = this.reference.object.productId;
      this.initLang();
      this.title = 'New Product Version';
      this.setDefaultCurrency();
    } else if (this.reference.action === 'edit') {
      this.model = ProductVersionModel.parse(this.reference.object);
      this.title = `Edit ${this.model.internalSku} (${this.model.id})`;
      this.getImages();
      this.initTags();
    }
    this.getCurrencies();
    this.getProducts();
    this.getChannels();
    this.getLanguage();

  }

  removeItem(item: ListItemModel, list: Array<ListItemModel> | any) {
    const index = list.map(function (e) {
      return e;

    }).indexOf(item);
    list.splice(index, 1);
  }
  initLang() {
    this.prodSrv.getVersionsVerbose(this.model.id).subscribe(
      data => this.onGetVersionVerbose(data),
      err => this.onGetVersionVerboseError(err));
  }
  getLangName(id: number): string {
    const lang = this.languageOptions.find(x => x.id === id);
    if (lang) {
      return lang.value;
    }
    return '';
  }
  verboseCreateIfNew(x: ListItemModel) {
    const lang = this.versionLang.find(vl => x.id === vl.languageId);
    if (!lang && this.model) {
      this.versionLang.push(ProductVersionVerboseModel.create(x.id, this.model.id));
      this.versionLangOriginal.push(ProductVersionVerboseModel.create(x.id, this.model.id));
    }
  }
  
  checkIfVerboseModify() {
    for (let i = 0; i < this.versionLang.length; i++) {
      if (this.checkIfVerboseModifyAction(i)) {
        this.descriptionModified = true;
        return;
      }
    }
    this.descriptionModified = false;
  }
  checkIfVerboseModifyAction(index: number): boolean {
    return (this.versionLang[index].name !== this.versionLangOriginal[index].name
      || this.versionLang[index].shortDesc !== this.versionLangOriginal[index].shortDesc
      || this.versionLang[index].desc !== this.versionLangOriginal[index].desc);
  }
  onGetVersionVerbose(data) {
    if (data['result']) {
      this.versionLang = [];
      this.versionLangOriginal = [];
      data['result'].forEach(x => {
        this.versionLang.push(ProductVersionVerboseModel.parse(x));
        this.versionLangOriginal.push(ProductVersionVerboseModel.parse(x));
      });
      this.languageOptions.forEach(x => this.verboseCreateIfNew(x));
    }
    this.onLanguageSelectionChange('');
  }
  onGetVersionVerboseError(err) {
    this.onError(err);
  }

  onLanguageSelectionChange(event) {
    if (event === '') {
      const selectedLanguage = this.languages.filter(x => x.name.toLowerCase() === localStorage.getItem('current_lang'));
      const selected = this.versionLang.filter(x => Number(x.languageId) === Number(selectedLanguage[0].id));
      this.selectedVersionLang = selected[0];
      this.selectedLanguage = selected[0].languageId.toString() === "2" ? "3" : "1";
    } else {
      const selection = this.versionLang.filter(x => Number(x.languageId) === Number(event.target.value))
      this.selectedVersionLang = selection[0];
    }
  }

  upload(evt: any) {
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>(evt.target);
    if (target.files.length !== 1) { 
      this.toastr.error('Cannot use multiple files');
      return;
     }
    if (target?.files[0]?.type !== 'application/vnd.ms-excel' && target?.files[0]?.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      this.toastr.error('Invalid file format. Only Excel files are allowed.');
      return;
    }
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });
      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];
      /* save data */
      const dataUpload = <any[]>(XLSX.utils.sheet_to_json(ws, { header: ['token', 'link'] }));

      if(dataUpload?.length > 1) {
      const parseData: Array<TokenOtkViewModel> = [];
      for (let i = 1; i < dataUpload.length; i++) {
        parseData.push(new TokenOtkViewModel(0, this.model.id, null, this.model.channelId,
          dataUpload[i]['link'],
          dataUpload[i]['token'],
          null, true, new Date(), null, null,0));
      }
      this.prodSrv.uploadOtk(parseData).subscribe(data => {
       if(data?.result) {
        this.toastr.success(this.i18Svc.getTranslateVal('success_upload_txt'));
        this.inputVal = 0;
        this.tokenTable?.refresh();
       } else {
        this.toastr.error(this.i18Svc.getTranslateVal('error_upload_txt'));
       }
      },
        err => this.onError(err));
    }
    };
    reader.readAsArrayBuffer(target.files[0]);
  }

  onVersionImageUploadSuccess(data) {
    this.imgFlag = true;
    this.imgLoading = true;
    if (this.imgFlag && this.versionImages.length > 0) {

      this.prodSrv.UpdateVersionImagePriorities(this.model.id, Number(this.versionImages[this.versionImages.length - 1].priority) + 1, data[1]['result'].id).subscribe((data) => {

        this.onGetImages(data)
        this.imgFlag = false;

      },
        err => this.onGetImagesError(err));

    }
  }
  onVersionImageUploadError(data) {

  }
  getImages() {
    this.imgLoading = true;
    this.prodSrv.getImages(this.model.id).subscribe(
      data => this.onGetImages(data),
      err => this.onGetImagesError(err));
  }


  onGetImages(data) {
    this.imgLoading = false;
    if (data['result']) {
      this.versionImages = [];
      this.versionImagesOriginal = [];
      data['result'].forEach(x => {
        this.versionImages.push(ProductVersionImageModel.parse(x));
        this.versionImagesOriginal.push(ProductVersionImageModel.parse(x));
      });

      this.setVersionImageSize();
    }
  }

  onGetImagesError(err) {
    this.imgLoading = false;
    this.onError(err);
  }
  checkIfImageModify() {
    for (let i = 0; i < this.versionImages.length; i++) {
      if (this.checkIfImageModifyAction(i)) {
        this.imagesModified = true;
      }
    }
    this.imagesModified = false;
  }
  checkIfImageModifyAction(index: number) {
    return (this.versionImages[index].alt !== this.versionImagesOriginal[index].alt ||
      this.versionImages[index].isActive !== this.versionImagesOriginal[index].isActive);
  }

  copyToClipboard(inputElement) {
    inputElement.select();
    inputElement.setSelectionRange(0, 0);
    navigator.clipboard.writeText(inputElement?.value);
  }
  selectDefaultImage(image: any) {
    this.versionImages.forEach(x => x.setSelected(false));
    image.isSelected = true;
  }

  // Description
  descLangSelection(id: number) {
    this.selectedDescNav = id;
  }
  setDefaultCurrency() {
    if (this.currencyOptions.length > 0) {
      if (this.reference.action === 'new') {
        this.model.purchaseCurrencyId = this.currencyOptions[0].id;
        this.model.purchaseCurrency = this.currencyOptions[0].value;
      } else {
        const currency = this.currencyOptions.find(x => x.id === this.model.purchaseCurrencyId);
        this.model.purchaseCurrency = currency.value;
      }
    }
  }
  getChannels() {
    this.prodSrv.getChannels().subscribe(
      data => {
        if (data['result']) {
          this.channelOptions = [];
          data['result'].forEach(x =>
            this.channelOptions.push(ListItemModel.Parse(x)));
        }
      },
      err => this.onError(err));
  }
  getProducts() {
    this.prodSrv.getProductsAsOptions().subscribe(
      data => {
        if (data['result']) {
          this.productOptions = [];
          data['result'].forEach(x =>
            this.productOptions.push(ListItemModel.Parse(x)));
        }
      },
      err => this.onError(err));
  }
  getCurrencies() {
    this.confSrv.getCurrencyOptions().subscribe(
      data => this.onGetCurrencies(data),
      err => this.onError(err));
  }
  onGetCurrencies(data) {
    if (data['result']) {
      this.currencyOptions = [];
      data['result'].forEach(x =>
        this.currencyOptions.push(ListItemModel.Parse(x)));
    }
    this.setDefaultCurrency();
  }

  getLanguage() {
    this.confSrv.getLanguageOptions().subscribe(
      data => this.onGetLanguage(data),
      err => this.onError(err));
  }
  onGetLanguage(data) {
    if (data['result']) {
      this.languageOptions = [];
      data['result'].forEach(x =>
        this.languageOptions.push(ListItemModel.Parse(x)));
      this.initLang();
    }
  }

  onError(err) {
    this.toastr.error('Something went wrong!');
  }

  // TAGS
  initTags() {
    this.getTagsInVersion();
    this.getTagsAvailable();
  }
  getTagsInVersion() {
    this.prodSrv.getTagsInVersion(this.model.id).subscribe(
      data => this.onGetTagsInVersion(data),
      err => this.onGetTagsInVersionError(err));
  }
  onGetTagsInVersion(data) {
    if (data['result']) {
      this.tagsOnVersion = [];
      data['result'].forEach(x =>
        this.tagsOnVersion.push(ListItemModel.Parse(x)));
    }
  }
  onGetTagsInVersionError(err) {
    this.onError(err);
  }
  getTagsAvailable() {
    this.prodSrv.getTagsAvailableForVersion(this.model.id).subscribe(
      data => this.onGetTagsAvailableForVersion(data),
      err => this.onGetTagsAvailableForVersionError(err));
  }
  onGetTagsAvailableForVersion(data) {
    if (data['result']) {
      this.tagsAvailable = [];
      data['result'].forEach(x =>  this.tagsAvailable.push(ListItemModel.Parse(x)));
    }
  }
  onGetTagsAvailableForVersionError(err) {
    this.onError(err);
  }

  onAddOnGetTagsInVersion(data) {
    if (data['result']) {
      this.tagsAddOnOnVersion = [];
      data['result'].forEach(x =>
        this.tagsAddOnOnVersion.push(ListItemModel.Parse(x)));
    }
  }
  onAddOnGetTagsInVersionError(err) {
    this.onError(err);
  }

  onAddOnGetTagsAvailableForVersion(data) {
    if (data['result']) {
      this.tagsAddOnAvailable = [];
      data['result'].forEach(x =>
        this.tagsAddOnAvailable.push(ListItemModel.Parse(x)));
    }
  }
  onAddOnGetTagsAvailableForVersionError(err) {
    this.onError(err);
  }


  // Modal General UI Controls
  validModel(): boolean {
    return this.model.isValid();
  }
  close() {
    this.open = false;
    this.modalClose.emit(this.isChanged);
  }

  setVersionImageSize() {
    setTimeout(() => {
      this.versionImagesList.forEach((x: ElementRef<HTMLElement>) => {
        const parentElement = x.nativeElement?.children[0];
        const imageElement = parentElement.querySelector('img');
        if (imageElement?.complete) {
          console.log('complete');
        } else {
          imageElement?.addEventListener('load', function () {
            console.log('load');
          });
        }
      });
    }, 1);
  }

  save(closeOnComplete: boolean) {
    if (this.validModel()) {
      if (this.model.productId === 0) {
        // Create new product first
        this.createProduct(closeOnComplete);
      } else {

        if (this.reference.action === 'edit') {
          this.requestSent++;
          this.prodSrv.updateVersion(this.model)
            .subscribe(data => this.onUpdateMain(data, closeOnComplete),
              err => this.onError(err));
          if (this.descriptionModified) {
            for (let i = 0; i < this.versionLang.length; i++) {
              if (this.checkIfVerboseModifyAction(i)) {
                this.requestSent++;
                this.prodSrv.updateVersionLang(this.versionLang[i])
                  .subscribe(data => this.onUpdate(data, closeOnComplete),
                    err => this.onUpdateError(err));
              }
            }
          }
          if (this.tagsModified) {
            this.requestSent++;
            this.prodSrv.updateVersionTags(this.tagsOnVersion, this.model.id)
              .subscribe(data => this.onUpdate(data, closeOnComplete),
                err => this.onUpdateError(err));
          }
          for (let i = 0; i < this.versionImages.length; i++) {
            if (this.checkIfImageModifyAction(i)) {
              this.requestSent++;
              this.updateservice(this.versionImages[i], closeOnComplete);
            }
          }
        } else {
          this.loadingVersion = true;
          this.prodSrv.createVersion(this.model)
            .subscribe(data => this.onCreate(data, closeOnComplete),
              err => {
                this.loadingVersion = false;
                this.onError(err);
              });
        }
      }
    }
  }

  get isLoading(): boolean {
    return this.loadingProduct || this.loadingVersion || this.requestSent > 0;
  }

  createProduct(closeOnComplete: boolean) {
    this.newProduct.defaultVersionId = 0;
    this.newProduct.channelId = this.model.channelId;
    this.newProduct.channelTypeId = 1; // DEFAULT TO ELECTRONIC
    this.loadingProduct = true;
    this.prodSrv.create(this.newProduct).subscribe(
      data => {
        this.isChanged = true;
        this.loadingProduct = false;
        if (data['result']) {
          this.model.productId = data['result'].id;
          this.save(closeOnComplete);
        } else {
          this.onError(data['errorMessage']);
        }
      },
      error => {
        this.loadingProduct = false;
        this.onError(error);
      });
  }
  onUpdateMain(data, closeOnComplete: boolean) {
    this.isChanged = true;
    if (data['result']) {
      this.reference.object = ProductVersionModel.parse(data['result']);
    }
    this.onUpdate(data, closeOnComplete);
  }
  onUpdate(data, closeOnComplete: boolean) {
    this.isChanged = true;
    this.requestSent--;
    this.showMessage(data['result']);
    if (data['result']) {
      if (this.requestSent === 0) {
        if (closeOnComplete) {
          this.close();
        } else {
          this.reference.action = 'edit';
          this.initAction();
        }
      }
    } else {
      this.toastr.error('Could not create version, please verify all your fields for errors');
    }

  }
  onUpdateError(err) {
    this.requestSent--;
    this.errorMsg = `${this.errorMsg} ${err}`;
  }
  displayMsgIfError() {
    if (this.errorMsg) {
      this.onError(this.errorMsg);
    }
  }
  onCreate(data, closeOnComplete) {
    this.isChanged = true;
    this.loadingVersion = false;
    if (data['result']) {
      this.displaySuccessMsg();
      this.reference.action = 'edit';
      this.reference.object = ProductVersionModel.parse(data['result']);
      this.getLanguage();
      this.initAction();
      if (closeOnComplete) {
        this.close();
      }
    }
  }


  selectImage(scid: ProductVersionImageModel): void {
    if (this.selectedImage === scid) {
      this.selectedImage = null;
    } else {
      this.selectedImage = scid;
    }
  }

  onUpdateImage(): void {
    this.isChanged = true;
    this.selectedImage.isActive = !this.selectedImage.isActive;
    const currentImage = this.versionImages.findIndex(x => x.id === this.selectedImage.id);
    this.versionImages[currentImage].isActive = this.selectedImage.isActive;
    // updateVersionImage
    console.log('modelo que se manda',this.selectedImage);
    this.updateservice(this.selectedImage,false);    

  }
  isTokenTabSelected: boolean;
  tabSelection(index: number): void {
    this.isImageTabActive = index === 3;
    this.isTokenTabSelected = index === 5;
    if (this.isImageTabActive) {
      this.setVersionImageSize();
    }
  }

  deleteImage(): void {
    this.prodSrv.deleteImage(this.selectedImage.id)
      .subscribe(() => this.displayDeleteSuccessMsg(), err => this.onError(err));
  }

  setDefaultImage(): void {
    this.isChanged = true;
    for (let i = 0; i < this.versionImages.length; i++) {
      if (this.versionImages[i].id === this.selectedImage.id) {
        this.versionImages[i]._Selected = true;
        this.versionImages[i]._Loading = true;
        this.versionImages[i].versionId = 1;
        this.versionImages[i].priority = 2;
        this.updateservice(this.versionImages[i], false);
      } else {
        this.versionImages[i]._Selected = false;
        this.versionImages[i]._Loading = false;
        this.versionImages[i].versionId = 1;
        this.versionImages[i].priority = 3;
        this.updateservice(this.versionImages[i], false);

      }
    }
  }

  openProductAlert(): void {
    this.hideModal = true;
    this.openProductAlertModal = true;
    this.modalRef = new ModalTransferModel('edit', ProductAlertModalComponent, this.model, null, null);
  }

  onProductAlertModalClose(): void {
    this.openProductAlertModal = false;
    this.hideModal = false;
  }

  openHealthCheck(): void {
    this.healthCheckResult = [];
    this.prodSrv.getHealthCheck(this.model.id)
      .subscribe((data) => {
        this.healthCheckResult = data['result'];
        this.openHealthCheckModal = true;
        this.hideModal = true;
      });
  }

  closeHealthCheckModal(): void {
    this.openHealthCheckModal = false;
    this.hideModal = false;
  }

  showMessage(success): void {
    const mode = this.reference.action === 'new' ? 'create' : 'update';
    if (success) {
      this.toastr.success('your product version has been ' + mode + 'd: ');
    } else {
      this.toastr.error('failed to ' + mode + ' product version: ');
    }
  }

  displayImageDefaultSuccessMsg() {
    this.toastr.success('Image set to default.');
  }

  displayDeleteSuccessMsg() {
    this.isChanged = true;
    this.toastr.success('Image have been removed');
    this.getImages();
  }

  displaySuccessMsg() {
    this.toastr.success('Version have been saved');
  }

  get isPhysical() {
    return this.model?.isPhysical
  }

  openInvoiceViewModal: boolean;
  modalReference: ModalTransferModel;

  edit(tokenId: number): void {
    this.hideModal = true;
    this.openInvoiceViewModal = true;

    this.modalReference = new ModalTransferModel('new', InvoiceViewModalComponent, tokenId, null, null);
  }

  onInvoiceViewModalClose(_): void {
    this.openInvoiceViewModal = false;
    this.hideModal = false;
  }
  // this.i18Svc.getTranslateVal('marketing_disable_info')
  @ViewChild('file') file!: ElementRef;
  createReport(event: any, obj: any) {
    if (event.target.value == 2) {
      let arrTitles = [this.i18Svc.getTranslateVal('token_text'), this.i18Svc.getTranslateVal('url_text')];
      let arrObj = [];
      this.exportService.exportDocument('xls', arrObj, arrTitles, 'OTK_UploadTokenTemplate_');
      this.inputVal = 0;
    }
    else if (event.target.value == 1) {
      this.file.nativeElement.click();
    }
  }

   compareObjects(obj1: any, obj2: any,obj3: any): boolean {
    // Get the keys of both objects
    const obj1Keys = Object.keys(obj1);
    const obj2Keys = Object.keys(obj2);
    const obj3Keys = Object.keys(obj3);
  
    // Check if the number of keys is the same
    if (obj1Keys.length !== obj2Keys.length || obj1Keys.length !== obj3Keys.length) {
      return false;
    }
  
    // Check if the values of each property are the same
    for (let key of obj1Keys) {
      if (obj1[key] !== obj2[key] && obj1[key] !== obj3[key]) {
        return false;
      }
    }
  
    // If all checks pass, the objects are equal
    return true;
  }
  

  updateservice(versionIMG:ProductVersionImageModel,closeOnComplete:boolean ){
    this.prodSrv.updateVersionImage(versionIMG)
                .subscribe(data => {
                  console.log('la data',data);
                  this.onUpdate(data, closeOnComplete
                    )},
                  err => this.onUpdateError(err));
  }

  ngOnDestroy(): void {
    this.subs?.unsubscribe();
  }

}
