import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BannerDefault, TopBannerModel } from '../../../../models/marketing/topbanner.model';

@Component({
  selector: 'app-marketing-slider',
  templateUrl: './marketing-slider.component.html',
  styleUrls: ['./marketing-slider.component.scss']
})
export class MarketingSliderComponent implements OnInit {
  isImageLoaded: boolean;
  sliderItem: TopBannerModel;
  actualPrice: number;
  showHeader: boolean;
  showSubheader: boolean;
  showBackgroundUrl: boolean;
  showProductUrl: boolean;
  constructor(private router: ActivatedRoute) {
    this.sliderItem = new TopBannerModel(0, 0, 0, '', '', '', null, '', '', 0, null, null, true, BannerDefault.color, 
    BannerDefault.color, BannerDefault.color, BannerDefault.color,'', BannerDefault.directionConfig);
  }

  ngOnInit() {
    this.router.queryParams.subscribe(params => {
      const { productUrl, backgroundUrl, header, subheader, buyBtnColor, headerColor, redirectBtnColor, subheaderColor, directionConfig, redirectUrl,
        showHeader, showSubheader, showBackgroundUrl, showProductUrl } = params; 
      this.sliderItem.backgroundUrl = backgroundUrl;
      this.sliderItem.productUrl = productUrl;
      this.sliderItem.header = header;
      this.sliderItem.subheader = subheader;
      this.sliderItem.buyBtnColor = buyBtnColor!=="undefined" ? buyBtnColor : BannerDefault.color;
      this.sliderItem.headerColor = headerColor!=="undefined" ? headerColor : BannerDefault.color;
      this.sliderItem.redirectBtnColor = redirectBtnColor!=="undefined" ? redirectBtnColor : BannerDefault.color;
      this.sliderItem.subheaderColor = subheaderColor!=="undefined" ? subheaderColor : BannerDefault.color;
      this.sliderItem.directionConfig = directionConfig!=="undefined" ? directionConfig : BannerDefault.directionConfig;
      this.sliderItem.redirectUrl = redirectUrl || '';
      this.actualPrice = 100;
      
      this.showHeader = this.isTrue(showHeader);
      this.showSubheader = this.isTrue(showSubheader);
      this.showBackgroundUrl = this.isTrue(showBackgroundUrl);
      this.showProductUrl = this.isTrue(showProductUrl);
    });
  }

  isTrue(val?: string) {
    return val?.toString()?.toLowerCase() === 'true';
   }

  checkDirectionConfig(val: number) {
    return this.sliderItem?.directionConfig?.toString()?.toLowerCase() === val?.toString()?.toLowerCase();
  }

  onImageLoad(): void {
    this.isImageLoaded = true;
  }

}
