import { PriceListModel } from './product/price-list/price-list.model';
import { CurrencyModel } from './billing/currency.model';
import { TaxGroupModel } from './billing/tax.model';
import { SelectableModel } from './general/selectable.model';

export class AccountModel {
    id: number;
    name: string;
    number: string;
    accountingNo: string;
    referenceNo: string;
    orderEnable: boolean;
    apiEnable: boolean;
    supportEnable: boolean;
    autoApproveEnable: boolean;
    autoProvissioningEnable: boolean;
    discountCodeEnable: boolean;
    mopCreditCartEnable: boolean;
    mopCreditLineEnable: boolean;
    mopCreditLineLimit: number;
    allowOverLimit: boolean;
    allowOverEstimateLimit: boolean;
    billingDate: string;
    isTaxable: boolean;
    isEnable: boolean;
    notes: string;

    billingContactId: number;
    shippingContactId: number;
    supportContactId: number;

    accountOwner: number;

    billingUser: number;
    supportUser: number;
    purchaseUser: number;

    languageId: number;
    taxId: number;
    currencyId: number;
    regionId: number;
    priceSheetId: number;


    address1: string;
    address2: string;
    city: string;
    state: string;
    postalCode: string;

    notificationEmail?: string;
    isActive?: boolean;


    constructor(id: number,
        name: string,
        number: string,
        accountingNo: string,
        referenceNo: string,
        orderEnable: boolean,
        apiEnable: boolean,
        supportEnable: boolean,
        autoApproveEnable: boolean,
        autoProvissioningEnable: boolean,
        discountCodeEnable: boolean,
        mopCreditCartEnable: boolean,
        mopCreditLineEnable: boolean,
        mopCreditLineLimit: number,
        allowOverLimit: boolean,
        allowOverEstimateLimit: boolean,
        billingDate: string,
        isTaxable: boolean,
        isEnable: boolean,
        notes: string,
        billingContactId: number,
        shippingContactId: number,
        supportContactId: number,
        accountOwner: number,
        billingUser: number,
        supportUser: number,
        purchaseUser: number,
        languageId: number,
        taxId: number,
        currencyId: number,
        regionId: number,
        address1: string,
        address2: string,
        city: string,
        state: string,
        postalCode: string,
        priceSheetId: number,
        notificationEmail?: string) {
        this.id = id;
        this.name = name;
        this.number = number;
        this.accountingNo = accountingNo;
        this.referenceNo = referenceNo;
        this.orderEnable = orderEnable;
        this.apiEnable = apiEnable;
        this.supportEnable = supportEnable;
        this.autoApproveEnable = autoApproveEnable;
        this.autoProvissioningEnable = autoProvissioningEnable;
        this.discountCodeEnable = discountCodeEnable;
        this.mopCreditCartEnable = mopCreditCartEnable;
        this.mopCreditLineEnable = mopCreditLineEnable;

        this.mopCreditLineLimit = mopCreditLineLimit;
        this.allowOverLimit = allowOverLimit;
        this.allowOverEstimateLimit = allowOverEstimateLimit;
        this.billingDate = billingDate;
        this.isTaxable = isTaxable;
        this.isEnable = isEnable;
        this.notes = notes;
        this.billingContactId = billingContactId;
        this.shippingContactId = shippingContactId;
        this.billingContactId = billingContactId;
        this.supportContactId = supportContactId;
        this.accountOwner = accountOwner;
        this.billingUser = billingUser;
        this.supportUser = supportUser;
        this.purchaseUser = purchaseUser;

        this.languageId = languageId;
        this.taxId = taxId;
        this.currencyId = currencyId;
        this.regionId = regionId

        this.address1 = address1;
        this.address2 = address2;
        this.city = city;
        this.state = state;
        this.postalCode = postalCode;
        this.priceSheetId = priceSheetId;
        this.notificationEmail = notificationEmail;
    }

    public static createNew(regionId: number): AccountModel {
        return new AccountModel(0, '', '', '', '', true, false, false, false, true,
            true, false, true, 0, false, false, '', true, true, '', 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, regionId, '', '', '', '', '', 0, '');
    }

    public sanitize(): void {
        this.name = this.name.trim();
        this.number = this.number.trim();
        this.accountingNo = this.accountingNo.trim();
        this.referenceNo = this.referenceNo.trim();
    }

    public IsValid() {
        return true;
    }


}
export interface IAccountRegionModel {
    id: number;
    name: string;
    tax: TaxGroupModel;
    priceList: PriceListModel;
    marginDefault: number;
    currency: CurrencyModel;
    accountContact: any;
    regionOwner: any;
    isActive: boolean;
}
export class AccountRegionModel extends SelectableModel implements IAccountRegionModel {

    id: number;
    name: string;
    tax: TaxGroupModel;
    priceList: PriceListModel;
    marginDefault: number;
    currency: CurrencyModel;
    accountContact: any;
    regionOwner: any;
    isActive: boolean;

    address1: string;
    address2: string;
    city: string;
    state: string;
    postalCode: string;
    country: string;

    phone: string;
    website: string;
    email: string;

    constructor(id: number,
        name: string,
        tax: TaxGroupModel,
        marginDefault: number,
        priceList: PriceListModel,
        currency: CurrencyModel,
        accountContact: any,
        regionOwner: any,
        isActive: boolean,
        address1: string,
        address2: string,
        city: string,
        state: string,
        postalCode: string,
        country: string,
        phone: string,
        website: string,
        email: string) {
        super();
        this.id = id;
        this.name = name;
        this.tax = tax;
        this.marginDefault = marginDefault;
        this.priceList = priceList;
        this.currency = currency;
        this.accountContact = accountContact;
        this.regionOwner = regionOwner;
        this.isActive = isActive;
        this.address1 = address1;
        this.address2 = address2;
        this.city = city;
        this.state = state;
        this.postalCode = postalCode;
        this.country = country;
        this.phone = phone;
        this.website = website;
        this.email = email;
    }

    public static create(): AccountRegionModel {
        return new AccountRegionModel(0, '', null, 0, null, null, null, null, true, '','', '', '', '', '', '','','');
    }

    public static parse(data): AccountRegionModel {
        const tax = data['tax'] ? TaxGroupModel.parse(data['tax']) : null;
        const pricelist = data['priceList'] ? PriceListModel.Parse(data['priceList']) : null;
        const currency = data['currency'] ? CurrencyModel.parse(data['currency']) : null;
        return new AccountRegionModel(
            data['id'],
            data['name'],
            tax,
            data['marginDefault'],
            pricelist,
            currency,
            data['contact'],
            data['regionOwner'],
            data['isActive'],

            data['address1'],
            data['address2'],
            data['city'],
            data['state'],
            data['postalCode'],
            data['country'],
            data['phone'],
            data['website'],
            data['email']
        );
    }

}

export class ApiModel {
    id: number;
    key: string;
    secret: string;
    isActive: boolean;

    constructor(id: number,
        key: string,
        secret: string,
        isActive: boolean) {

        this.id = id;
        this.key = key;
        this.secret = secret;
        this.isActive = isActive;
    }

    public static parse(data): ApiModel {
        return new ApiModel(data['id'],
            data['key'],
            data['secret'],
            data['isActive'])
    }
}


export interface AccountContactModel {
    id: number
    accountId: number
    name: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    country: string;
    zipCode: string;
    phone1: string;
    phone2: string;
    fax: string;
    email: string;
    isActive: boolean;
}